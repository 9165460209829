<template>
  <div ref="header" id="header">
    <div id="headCont" class="header">
      <div class="wrapper">
        <div class="menu_pc flex_c_s">
          <div class="itemBox flex">
            <div class="item flex pointer" :class="{ sel: menuIndex == index }" v-for="(item, index) in menuList"
              :key="index" @click="checkNav(index, item.link)">{{ item.name }}</div>
          </div>
          <div class="flex">
            <template v-if="isLogged">
              <div class="userInfo mr-20">{{ username }}</div>
              <div class="exit mr-20" @click="logout">{{ $t('exit') }}</div>
            </template>
            <template v-else>
              <div class="loginLink mr-20 pointer" @click="router.push('/login')">{{ $t('loginNav[0]') }}</div>
              <div class="regLink mr-20 pointer" @click="router.push('/register')">{{ $t('loginNav[1]') }}</div>
            </template>
            <!-- <div class="lang">
              <div class="pointer flex">
                <img class="img mr-10" src="@/assets/img/lang.png">
                <div class="name">{{ currentLang }}</div>
                <img class="arrow ml-10" src="@/assets/img/arrow_down.png">
              </div>

              <div class="downLang">
                <div class="mt-10 pointer">中文</div>
                <div class="mt-10 pointer">English</div>
              </div>
            </div> -->

          </div>
        </div>
        <div class="menu_mobile flex_c_s">
          <div class="title flex_c_c">
            <div class="mobileTitle">{{ $t('mobileTitle') }}</div>
            <div class="ico">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
          <div class="user flex">
            <template v-if="isLogged">
              <div class="userInfo mr-20">{{ username }}</div>
              <div class="exit mr-20" @click="logout">{{ $t('exit') }}</div>
            </template>
            <template v-else>
              <div class="loginLink mr-20" @click="router.push('/login')">{{ $t('loginNav[0]') }}</div>
              <div class="regLink mr-20" @click="router.push('/register')">{{ $t('loginNav[1]') }}</div>
            </template>

            <!-- <div class="lang">
              <div class="pointer flex">
                <img class="img mr-10" src="@/assets/img/lang.png">
                <div class="name">{{ currentLang }}</div>
                <img class="arrow ml-10" src="@/assets/img/arrow_down.png">
              </div>

              <div class="downLang">
                <div class="mt-10 pointer">中文</div>
                <div class="mt-10 pointer">English</div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="popDrawer">
          <div class="bg"></div>
          <div class="menu_mobile_list">
            <div class="close flex_c_s">
              <div class="mobileTitle">{{ $t('mobileTitle') }}</div>
              <div class="ico">×</div>
            </div>
            <div class="item flex pointer" :class="{ sel: menuIndex == index }" v-for="(item, index) in menuList"
              :key="index" @click="checkNav(index, item.link)">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { onMounted, onUnmounted, ref, computed } from "vue";
import { useStore } from 'vuex'
import { zh, en } from '@/assets/js/lang.js'
import { tt } from '@/i18n'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()

const menuList = ref([
  { name: tt('menu[0].name'), link: '/index' },
  { name: tt('menu[1].name'), link: '/message' },
  { name: tt('menu[2].name'), link: '/checkIn' },
  { name: tt('menu[3].name'), link: '/law' },
  { name: tt('menu[4].name'), link: '/cases' },
  { name: tt('menu[5].name'), link: '/apply' },
  { name: tt('menu[6].name'), link: '/freeze' },
  { name: tt('menu[7].name'), link: '/notice' },
  { name: tt('menu[8].name'), link: '' },
])

// const language = computed(() => store.getters.language);
// 切换语言的方法
const { locale, t } = useI18n();
const store = useStore()
const changeLang = (lang) => {
  locale.value = lang;
  store.commit("app/setLanguage", lang);
  location.reload()
}

const currentLang = computed(() => {
  switch (store.getters.language) {
    case 'zh':
      return ('中文');
    case 'en':
      return ('English');
  }
})

const menuIndex = ref(0);

const isLogged = ref(false)

const checkNav = (index, link) => {
  menuIndex.value = index
  router.push(link)
}

const logout = () => {
  localStorage.removeItem('username');
  localStorage.removeItem('token');
  isLogged.value = false
  router.push('/login')
}

const username = ref(undefined)

onMounted(() => {
  username.value = localStorage.getItem('username')
  if (username.value) {
    isLogged.value = true
  }
})

$(function () {
  $('.lang .name').click(function () {
    $('.downLang').toggle();
  })

  const langList = [
    { name: '中文', type: "zh" },
    { name: 'English', type: 'en' },
  ]

  $('.downLang .pointer').click(function () {
    var idx = $(this).index();
    localStorage.setItem('language', langList[idx].type);
    location.reload();
  })

  $('.menu_mobile .title').click(function () {
    $('.popDrawer').addClass('sel');
  })
  $('.popDrawer .bg').click(function () {
    $('.popDrawer').removeClass('sel');
  })
  $('.popDrawer .close').click(function () {
    $('.popDrawer').removeClass('sel');
  })

})
</script>

<style>
.header {
  color: #fff;
  height: 62px;
  width: 100%;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6000;
}

.header .menu_pc {
  background: #000000;
  box-shadow: 0px 2px 20px 0px #000000;
}

.header .menu_pc .itemBox {
  height: 62px;
}

.header .menu_pc .item {
  height: 62px;
  font-size: 16px;
  color: #666;
  margin-right: 20px;
  font-weight: bold;
}

.header .menu_pc .item:last-child {
  margin-right: 0;
}

.header .menu_pc .sel {
  color: #fff;
  position: relative;
}

.header .menu_pc .sel:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: #FFFFFF;
  position: absolute;
  bottom: 0;
  left: 0;
}

.header .lang {
  font-size: 16px;
  position: relative;
  margin-right: 20px;
}

.header .lang .img {
  width: 17px;
}

.header .lang .arrow {
  width: 12px;
}

.header .lang .downLang {
  position: absolute;
  left: 0;
  background: #fff;
  color: #333;
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
  display: none;
}

.header .menu_mobile {
  display: none;
  height: 62px;
  align-items: center;
  background: #000;
  padding-left: 15px;
}

.header .menu_mobile .title {
  height: 31px;
  padding: 0 10px;
  border: 2px solid #787878;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}

.header .menu_mobile .title .ico {
  margin-left: 20px;
}

.header .menu_mobile .title .ico .line {
  width: 15px;
  height: 2px;
  border-radius: 10px;
  background: #fff;
  display: block;
  margin-bottom: 3px;
}

.header .menu_mobile .title .ico .line:last-child {
  margin-bottom: 0;
}

.header .popDrawer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 100vh;
  display: none;
}

.header .popDrawer .bg {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 100vh;
}

.header .popDrawer .menu_mobile_list {
  color: #000;
  padding: 0 20px;
  background: #fff;
  width: 50%;
  height: 100vh;
  position: relative;
  z-index: 600;
}

.header .popDrawer .menu_mobile_list .item {
  padding: 10px 0;
}

.header .popDrawer .menu_mobile_list .item.sel {
  color: #337AB7;
  font-weight: bold;
}

.header .popDrawer .menu_mobile_list .close {
  padding: 20px 0;
  font-size: 20px;
}

.header .popDrawer.sel {
  display: block;
}

@media screen and (max-width: 1024px) {
  .header .menu_pc {
    display: none;
  }

  .header .menu_mobile {
    display: flex;
  }

}
</style>
import { createStore } from "vuex";
import app from './modules/app'

export default createStore({
  state: {},
  getters: {
    language: state => state.app.language
  },
  mutations: {},
  actions: {},
  modules: {
    app,
  },
});

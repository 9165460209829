import { createApp } from "vue";
import i18n from '@/i18n'
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@/assets/styles/style.css";
import { Buffer } from "buffer";
import process from "process";
// import { s3Layer } from 'vue3-layer';
import 'vue3-layer/dist/s3Layer.css';
const $ = require('jquery');
window.$ = $;
window.Buffer = window.Buffer || Buffer
window.process = process

createApp(App).use(store).use(router).use(i18n).mount("#app");

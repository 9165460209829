<template>
  <div ref="footer" id="footer">
    <div class="footCont flex_c_c">
      <div class="item flex_c_c">
        <img class="logo" src="@/assets/img/logo1.png">
        <div class="name">FBI</div>
        <div class="txt">
          Internet Crime <br>
          Complaint Center
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
onMounted(() => {

})
</script>

<style lang="scss" scoped>
.footCont {
  height: 140px;
  background: #000D1C;
}

.footCont .item {
  color: #fff;
}

.footCont .item .logo {
  width: 58px;
}

.footCont .item .name {
  margin-left: 15px;
  margin-right: 10px;
  font-size: 41px;
  font-weight: bold;
}

.footCont .item .txt {
  font-size: 16px;
  text-transform: uppercase;
}
</style>
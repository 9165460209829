import { createRouter, createWebHistory } from "vue-router";
import { layer } from "vue3-layer";
import { tt } from '@/i18n'

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      title: 'Home'
    }
  },
  {
    path: "/index",
    redirect: "/",
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "../views/login.vue"),
    meta: {
      title: 'Login'
    }
  },
  {
    path: "/register",
    name: "register",
    component: () => import(/* webpackChunkName: "register" */ "../views/register.vue"),
    meta: {
      title: 'Register'
    }
  },
  {
    path: "/message",
    name: "message",
    component: () => import(/* webpackChunkName: "message" */ "../views/message.vue"),
    meta: {
      title: 'Message'
    }
  },
  {
    path: "/checkIn",
    name: "checkIn",
    component: () => import(/* webpackChunkName: "checkIn" */ "../views/checkIn.vue"),
    meta: {
      title: 'CheckIn'
    }
  },
  {
    path: "/law",
    name: "law",
    component: () => import(/* webpackChunkName: "law" */ "../views/law.vue"),
    meta: {
      title: 'Law'
    }
  },
  {
    path: "/cases",
    name: "cases",
    component: () => import(/* webpackChunkName: "cases" */ "../views/cases.vue"),
    meta: {
      title: 'Cases'
    }
  },
  {
    path: "/apply",
    name: "apply",
    component: () => import(/* webpackChunkName: "apply" */ "../views/apply.vue"),
    meta: {
      title: 'Apply'
    }
  },
  {
    path: "/freeze",
    name: "freeze",
    component: () => import(/* webpackChunkName: "freeze" */ "../views/freeze.vue"),
    meta: {
      title: 'Freeze'
    }
  },
  {
    path: "/notice",
    name: "notice",
    component: () => import(/* webpackChunkName: "notice" */ "../views/notice.vue"),
    meta: {
      title: 'Notice'
    }
  },
];
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition;
  }
  return { left: 0, top: 0 };
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

/**
 * 全局前置路由守卫，每一次路由跳转前都进入这个 beforeEach 函数
 */
router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title
  next();
  // if (to.path == '/login' || to.path == '/register') {
  //   // 登录或者注册才可以往下进行
  //   window.document.title = to.meta.title
  //   next();
  // } else {
  //   // 获取 token
  //   const token = localStorage.getItem('token');
  //   // token 不存在
  //   if (!token || token === '') {
  //     layer.msg(tt('请先登录'), { icon: 2 })
  //     next('/login');
  //   } else {
  //     window.document.title = to.meta.title
  //     next();
  //   }
  // }
});

export default router;
